<template>
  <div class="wrapper-add-temp">
    <div class="canvas_box">
      <canvas id="canvas" width="880" height="625"/>
    </div>
    <div class="edit">
      <div class="padding-box">
        <span>模板类型：</span>
        <el-select v-model="curClassify" placeholder="请选择" size="small" style="width:150px">
          <el-option v-for="item in classify" :key="item" :label="item" :value="item"/>
        </el-select>
      </div>

      <div v-if="showName" class="padding-box">
        <span>模板名称：</span>
        <el-input v-model.trim="vmodelTeplateName" style="width:150px" size="small" placeholder="请输入内容"/>
      </div>
      <div class="flex_row padding-box">
        <el-upload ref="upload-wrap" class="upload-wrap" :action="uploadUrl" :headers="headers" :show-file-list="false"
                   accept=".jpg" :limit="1" :on-success="onSuccess"
        >
          <el-button size="small" type="primary">添加背景图</el-button>
        </el-upload>
        <el-button style="margin-left:10px" size="small" type="primary" @click="addText">添加文本框</el-button>
      </div>
      <div class="padding-box">
        <el-button size="small" type="primary" :disabled="vmodelTextDisabled" @click="delCurLevel">删除当前选中</el-button>
        <el-button size="small" type="primary" @click="reset">重置当前画布</el-button>
      </div>
      <div class="padding-box">
        <span>字体颜色：</span>
        <el-color-picker v-model="vmodelTextFill" color-format="hex" size="small" :disabled="vmodelTextDisabled"
                         :predefine="predefineColors" @active-change="textColorChange"
        />
      </div>
      <div class="padding-box">
        <span>字体大小：</span>
        <el-input-number v-model.trim="vmodelTextSize" style="width:100px" :disabled=" vmodelTextDisabled"
                         size="mini" :min="10" :max="200" label="描述文字" @change="textSizeChange"
        />
      </div>
      <div class="padding-box">
        <span>字体粗细：</span>
        <el-button-group>
          <!--          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textWeightChange(200)">细体-->
          <!--          </el-button>-->
          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textWeightChange(400)">正常
          </el-button>
          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textWeightChange(600)">粗体
          </el-button>
        </el-button-group>
        <!-- <el-select style="width:100px" v-model="vmodelTextWeight" :disabled="vmodelTextDisabled" placeholder="请选择"
          size="small" @change="textWeightChange">
          <el-option v-for="item in fontWeights" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
      </div>
      <div class="padding-box">
        <span>文本对齐：</span>
        <el-button-group>
          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textAlign('left')">左</el-button>
          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textAlign('center')">中
          </el-button>
          <el-button type="primary" size="small" :disabled="vmodelTextDisabled" @click="textAlign('right')">右
          </el-button>
        </el-button-group>
        <!-- <span style="color:#e6e6e6">注：全局居中时使用</span> -->
      </div>

      <div class="padding-box">
        <el-button size="small" style="width:240px" type="primary" @click="toImage">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {fabric} from 'fabric'

export default {
  components: {},
  props: {
    // fabricJson: { // 画布json信息
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // },
    uploadUrl: { // 图片上传地址
      type: String,
      required: true,
      default: ''
    },
    headers: { // 上传的头信息
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    showName: { // 是否显示模板名称输入框
      type: Boolean,
      default: false
    },
    textObj: { // 文本基础样式，详细请参考fabric
      type: Object,
      default: () => {
        return {
          left: 45,
          top: 45,
          width: 150,
          fontSize: 60, // 字体大小
          fontWeight: 600, // 字体粗细
          fill: '#FF0000', // 字体颜色
          fontStyle: 'normal', // 斜体
          fontFamily: 'sans-serif', // 设置字体
          stroke: 'green', // 描边颜色
          strokeWidth: 0, // 描边宽度
          hasControls: true,
          textAlign: 'left',
          lockUniScaling: true,
          borderColor: 'orange',
          editingBorderColor: 'blue' // 点击文字进入编辑状态时的边框颜色
        }
      }
    }

  },
  data() {
    return {
      card: {},
      fabricJson:{},
      vmodelTeplateName: '', // 模板的名字
      vmodelTextFill: this.textObj.fill, // 文本颜色
      vmodelTextSize: this.textObj.fontSize, // 文本大小
      vmodelTextWeight: this.textObj.fontWeight, // 文本粗细
      vmodelTextDisabled: true, // 是否选中了文本框
      curSelectLevel: {},
      classify: [],
      curClassify: '',
      fontWeights: [{value: 200, label: '细体'}, {value: 400, label: '正常'}, {value: 600, label: '粗体'}],
      predefineColors: [
        '#000000',
        '#FFFFFF',
        '#FF2525',
        '#27B148',
        '#00C7FF',
        '#3291F8',
        '#A411D1',
        '#FF7272',
        '#FF9959',
        '#FEDD59',
        '#59DBFF'
      ]
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getClassifyForDiy()
  },
  mounted() {
    this.init()
  },
  methods: {
    getClassifyForDiy() {
      this.$post("diy/getClassify").then(res => {
        this.classify = this.classify.concat(res.data[0].value.split(','))
      })
    },
    async init() {
      // eslint-disable-next-line no-unused-vars
      this.card = new fabric.Canvas('canvas')
      this.card.loadFromJSON(this.fabricJson, () => {
        this.card.renderAll()
      })
      this.card.on('selection:created', (e) => {
        console.log('selection:created', e.target.get('type'))
        const type = e.target.get('type') // 当前选中图层的类型
        this.curSelectLevel = e.target // 当前选中的图层
        this.syncTextInfo()
        if (type === 'textbox') {
          this.vmodelTextDisabled = false
        }
      })
      this.card.on('selection:updated', (e) => {
        console.log('selection:updated', e.target.get('type'))
        const type = e.target.get('type') // 当前选中图层的类型
        this.curSelectLevel = e.target // 当前选中的图层
        this.syncTextInfo()
        if (type === 'textbox') {
          this.vmodelTextDisabled = false
        }
      })
      this.card.on('selection:cleared', (e) => {
        console.log('selection:cleared')
        this.curSelectLevel = '' // 置空当前选中的图层
        this.vmodelTextDisabled = true
      })
      this.card.on('object:modified', (e) => {
        console.log(e.target) // e.target为当前编辑的Object
        // ...旋转，缩放，移动等编辑图层的操作都监听到
        // 所以如果有撤销/恢复的场景，这里可以保存编辑状态
      })
    },
    reloadModel(model) {
      if (!model) {
        return;
      }
      this.fabricJson = JSON.parse(model.content);
      this.vmodelTeplateName = model.modelName;
      this.curClassify = model.classify
      this.card.loadFromJSON(this.fabricJson, () => {
        this.card.renderAll()
      })
    },
    // 选中文本框时，同步工具栏数据
    syncTextInfo() {
      this.vmodelTextFill = this.curSelectLevel.fill // 文本颜色
      this.vmodelTextSize = this.curSelectLevel.fontSize // 文本大小
      this.vmodelTextWeight = this.curSelectLevel.fontWeight // 文本粗细
    },

    // upload组件上传
    onSuccess(response) {
      this.setCanvasBackGround(response.data)
      this.$refs['upload-wrap'].clearFiles()
    },
    /**
     * 添加画布背景
     * String { img }  ''
     * String { type } 'imgurl'||'color'
     */
    setCanvasBackGround(imgurl, type) {
      fabric.Image.fromURL(imgurl, (img) => {
        img.set({
          // 通过scale来设置图片大小，这里设置和画布一样大
          scaleX: this.card.width / img.width,
          scaleY: this.card.height / img.height
        })
        console.log(img)
        // 设置背景
        this.card.setBackgroundImage(img, this.card.renderAll.bind(this.card))
        this.card.renderAll()
      }, {
        crossOrigin: 'Anonymous'
      })
      // if (type === 'imgurl') {

      // }
    },
    // 添加文本框
    addText() {
      this.textObj.top = this.textObj.top + 20
      this.textObj.left = this.textObj.left + 20
      this.textObj.fill = this.vmodelTextFill
      const textbox = new fabric.Textbox('这是一段文字', this.textObj)
      textbox.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false
      })
      // 添加文本后
      this.card.add(textbox)
    },
    // 删除当前选中
    delCurLevel() {
      this.card.remove(this.curSelectLevel)
    },
    // 重置当前画布
    reset() {
      this.card.clear()
    },
    // 文本颜色
    textColorChange(color) {
      this.vmodelTextFill = color
      this.curSelectLevel.set('fill', this.vmodelTextFill)
      this.card.renderAll()
    },
    // 文本大小
    textSizeChange(fontSize) {
      // this.vmodelTextSize = fontSize
      this.curSelectLevel.set('fontSize', this.vmodelTextSize)
      this.card.renderAll()
    },
    // 文本粗细
    textWeightChange(fontWeight) {
      this.curSelectLevel.set('fontWeight', fontWeight)
      this.card.renderAll()
    },
    textAlign(textAlign) {
      this.curSelectLevel.set('textAlign', textAlign)
      this.curSelectLevel.set('width', 900 * (1 / this.curSelectLevel.scaleX))
      this.curSelectLevel.set('left', 0)
      this.card.renderAll()
    },
    // 生成文件并上传
    toImage() {
      if (this.showName && !this.vmodelTeplateName) {
        this.$message.error('请填写模板名称！')
        return
      }
      if (!this.curClassify) {
        this.$message.error('模板类型不能为空！')
        return
      }
      // 生成了base64格式的文件
      const dataUrl = this.card.toDataURL({
        format: 'jpeg',
        // quality: 0.5,
        multiplier: 1.5
      })
      const fileImg = this.dataURLtoFile(dataUrl, this.vmodelTeplateName || '') // 转化为File类型
      const content = this.card.toJSON()
      this.$emit('saveImage', {
        file: fileImg,
        fabricJson: content,
        classify: this.curClassify,
        name: this.vmodelTeplateName
      }) //
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    }
  }
}
</script>
<style lang="less" scoped>
.padding-box {
  padding: 6px;
}

.wrapper-add-temp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;

  .canvas_box {
    width: 885px;
    height: 630px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    overflow: hidden;
  }

  .edit {
    width: 350px;
    height: 386px;
    // border: 1px solid #e6e6e6;
    padding: 10px;

    .flex_row {
      display: flex;
      flex-direction: row;
    }
  }

  /deep/ .el-color-predefine__color-selector > div {
    border: solid 1px black !important;
  }
}
</style>
